import { createSlice } from "@reduxjs/toolkit";

export const CategorySlice = createSlice({
    name: "Category",
    initialState: {
        CategoryData: [],
    },
    reducers: {
        setCategoryData: (state, action) => {
            state.CategoryData = action.payload;
        },
        clearCategoryData: (state, action) => {
            state.CategoryData = [];
        },
    },
});

export const { setCategoryData, clearCategoryData } = CategorySlice.actions;

export const CategoryReducer = CategorySlice.reducer;
