import axios from "axios";
import { getBaseURL } from "../../helpers/getBaseURL";
import { getHeaderConfig } from "../../helpers/getHeaderConfig";
import * as ReducerActions from "../reducers";

export const getDashboardData = () => async (dispatch, getState) => {
    const config = getHeaderConfig(getState().User.Token);

    try {
        const res = await axios.get(
            `${getBaseURL()}/dashboard/get_count`,
            config
        );

        dispatch(ReducerActions.setDashboardData(res.data.data));
    } catch (error) {
        console.log(error);
        return error?.response?.data?.message;
    }
};
