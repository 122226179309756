import { createSlice } from "@reduxjs/toolkit";

export const TransactionSlice = createSlice({
    name: "Transaction",
    initialState: {
        TransactionData: {},
    },
    reducers: {
        setTransactionData: (state, action) => {
            state.TransactionData = action.payload;
        },
        clearTransactionData: (state, action) => {
            state.TransactionData = [];
        },
    },
});

export const { setTransactionData, clearTransactionData } =
    TransactionSlice.actions;

export const TransactionReducer = TransactionSlice.reducer;
