import axios from "axios";
import { getBaseURL } from "../../helpers/getBaseURL";
import { getHeaderConfig } from "../../helpers/getHeaderConfig";
import * as ReducerActions from "../reducers";

export const getProjectsData =
    (page = 1, rowsPerPage = 10, search = "") =>
    async (dispatch, getState) => {
        const config = getHeaderConfig(getState().User.Token);

        try {
            const res = await axios.get(
                `${getBaseURL()}/projects?page=${page}&rowsPerPage=${rowsPerPage}&search=${search}`,
                config
            );
            // console.log(res.data.data.data);

            dispatch(ReducerActions.setProjectsData(res.data.data));
        } catch (error) {
            console.log(error);
            return error?.response?.data?.message;
        }
    };

export const getProjectsList = () => async (dispatch, getState) => {
    const config = getHeaderConfig(getState().User.Token);

    try {
        const res = await axios.get(`${getBaseURL()}/projects/get/all`, config);

        dispatch(ReducerActions.setProjectsList(res.data.data));
    } catch (error) {
        console.log(error);
        return error?.response?.data?.message;
    }
};

export const AddNewProject = (body) => async (dispatch, getState) => {
    const config = getHeaderConfig(getState().User.Token);

    try {
        const res = await axios.post(
            `${getBaseURL()}/projects/store`,
            body,
            config
        );

        // if (!res.data?.success)
        //     return res?.data?.message ?? "Failed to add new project";

        return false;
    } catch (error) {
        return error?.response?.data?.data || "Something went wrong";
    }
};
