import { createSlice } from "@reduxjs/toolkit";

export const PlotsSlice = createSlice({
    name: "Plots",
    initialState: {
        PlotsData: [],
        SinglePlotData: [],
    },
    reducers: {
        setPlotsData: (state, action) => {
            state.PlotsData = action.payload;
        },
        setSinglePlotData: (state, action) => {
            state.SinglePlotData = action.payload;
        },
        clearSinglePlotData: (state) => {
            state.SinglePlotData = [];
        },
    },
});

export const { setPlotsData, setSinglePlotData, clearSinglePlotData } =
    PlotsSlice.actions;

export const PlotsReducer = PlotsSlice.reducer;
