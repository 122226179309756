import { createSlice } from "@reduxjs/toolkit";

export const ProjectsSlice = createSlice({
    name: "Projects",
    initialState: {
        ProjectsData: [],
    },
    reducers: {
        setProjectsData: (state, action) => {
            state.ProjectsData = action.payload;
        },
        setProjectsList: (state, action) => {
            state.ProjectsList = action.payload;
        },
    },
});

export const { setProjectsData, setProjectsList } = ProjectsSlice.actions;

export const ProjectsReducer = ProjectsSlice.reducer;
