import { createSlice } from "@reduxjs/toolkit";

export const UserSlice = createSlice({
    name: "User",
    initialState: {
        // UserData: localStorage.getItem("@UserData")
        //   ? JSON.parse(localStorage.getItem("@UserData"))
        //   : null,
        IsAuth: localStorage.getItem("@Token") ? true : false,
        Token: localStorage.getItem("@Token")
            ? JSON.parse(localStorage.getItem("@Token"))
            : null,
    },
    reducers: {
        setUserData: (state, action) => {
            state.UserData = action.payload;
        },
        loginSuccess: (state, action) => {
            // state.UserData = action.payload.User;
            // localStorage.setItem("@UserData", JSON.stringify(action.payload.User));
            state.IsAuth = true;
            state.Token = action.payload.data.token;
            localStorage.setItem(
                "@Token",
                JSON.stringify(action.payload.data.token)
            );
        },
        LogoutSuccess: (state) => {
            // state.UserData = null;
            // localStorage.removeItem("@UserData");
            state.IsAuth = false;
            state.Token = null;
            localStorage.removeItem("@Token");
        },
    },
});

export const { loginSuccess, LogoutSuccess, setUserData } = UserSlice.actions;

export const UserReducer = UserSlice.reducer;
