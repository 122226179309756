import { createSlice } from "@reduxjs/toolkit";

export const FinancialCategorySlice = createSlice({
    name: "FinancialCategory",
    initialState: {
        FinancialCategoryData: [],
        SubCategoryData: [],
    },
    reducers: {
        setFinancialCategoryData: (state, action) => {
            state.FinancialCategoryData = action.payload;
        },
        clearFinancialCategoryData: (state, action) => {
            state.FinancialCategoryData = [];
        },
        setSubCategoryData: (state, action) => {
            state.SubCategoryData = action.payload;
        },
        clearSubCategoryData: (state, action) => {
            state.SubCategoryData = [];
        },
    },
});

export const {
    setFinancialCategoryData,
    setSubCategoryData,
    clearFinancialCategoryData,
    clearSubCategoryData,
} = FinancialCategorySlice.actions;

export const FinancialCategoryReducer = FinancialCategorySlice.reducer;
