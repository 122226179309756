import { configureStore } from "@reduxjs/toolkit";

import {
    UserReducer,
    ProjectsReducer,
    AllUsersReducer,
    CategoryReducer,
    PlotsReducer,
    DashboardReducer,
    FinancialCategoryReducer,
    ClientReducer,
    AccountsReducer,
    TransactionReducer,
} from "./reducers";

export default configureStore({
    reducer: {
        User: UserReducer,
        Projects: ProjectsReducer,
        AllUsers: AllUsersReducer,
        Category: CategoryReducer,
        Plots: PlotsReducer,
        Dashboard: DashboardReducer,
        FinancialCategory: FinancialCategoryReducer,
        Client: ClientReducer,
        Account: AccountsReducer,
        Transaction: TransactionReducer,
    },
});
