import { createSlice } from "@reduxjs/toolkit";

export const ClientSlice = createSlice({
    name: "Client",
    initialState: {
        ClientData: [],
        ClientToEdit: {},
        SingleClientData: {},
    },
    reducers: {
        setClientData: (state, action) => {
            state.ClientData = action.payload;
        },
        clearClientData: (state, action) => {
            state.ClientData = [];
        },
        setClientToEdit: (state, action) => {
            state.ClientToEdit = action.payload;
        },
        clearClientToEdit: (state, action) => {
            state.ClientToEdit = {};
        },
        setSingleClientData: (state, action) => {
            state.SingleClientData = action.payload;
        },
        clearSingleClientData: (state, action) => {
            state.SingleClientData = {};
        },
    },
});

export const {
    setClientData,
    setClientToEdit,
    clearClientToEdit,
    clearClientData,
    setSingleClientData,
    clearSingleClientData,
} = ClientSlice.actions;

export const ClientReducer = ClientSlice.reducer;
