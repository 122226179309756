import { createSlice } from "@reduxjs/toolkit";

export const AllUsersSlice = createSlice({
  name: "AllUsers",
  initialState: {
    AllUsersData: {},
  },
  reducers: {
    setAllUsersData: (state, action) => {
      state.AllUsersData = action.payload;
    },
  },
});

export const { setAllUsersData } = AllUsersSlice.actions;

export const AllUsersReducer = AllUsersSlice.reducer;
