import { createSlice } from "@reduxjs/toolkit";

export const AccountSlice = createSlice({
    name: "Account",
    initialState: {
        AccountData: [],
    },
    reducers: {
        setAccountData: (state, action) => {
            state.AccountData = action.payload;
        },
        clearAccountData: (state, action) => {
            state.AccountData = [];
        },
    },
});

export const { setAccountData, clearAccountData } = AccountSlice.actions;

export const AccountsReducer = AccountSlice.reducer;
