import { createSlice } from "@reduxjs/toolkit";

export const DashboardSlice = createSlice({
    name: "Dashboard",
    initialState: {
        DashboardData: [],
    },
    reducers: {
        setDashboardData: (state, action) => {
            state.DashboardData = action.payload;
        },
    },
});

export const { setDashboardData } = DashboardSlice.actions;

export const DashboardReducer = DashboardSlice.reducer;
