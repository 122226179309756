import axios from "axios";
import { getBaseURL } from "../../helpers/getBaseURL";
import { getHeaderConfig } from "../../helpers/getHeaderConfig";
import * as ReducerActions from "../reducers";

export const getCategoryData =
    (page = 1, rowsPerPage = 10, search = "", project_id = "") =>
    async (dispatch, getState) => {
        const config = getHeaderConfig(getState().User.Token);

        try {
            const res = await axios.get(
                `${getBaseURL()}/categories?page=${page}&rowsPerPage=${rowsPerPage}&search=${search}&project_id=${project_id}`,
                config
            );
            // console.log(res.data.data.data);

            dispatch(ReducerActions.setCategoryData(res.data.data));
        } catch (error) {
            console.log(error);
            return error?.response?.data?.message;
        }
    };

export const AddNewCategory = (body) => async (dispatch, getState) => {
    const config = getHeaderConfig(getState().User.Token);

    try {
        const res = await axios.post(
            `${getBaseURL()}/categories/store`,
            body,
            config
        );

        return false;
    } catch (error) {
        return error?.response?.data?.data || "Something went wrong";
    }
};
