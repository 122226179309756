import axios from "axios";
import { getBaseURL } from "../../helpers/getBaseURL";
import { getHeaderConfig } from "../../helpers/getHeaderConfig";
import * as ReducerActions from "../reducers";

export const getPlotsData =
    (page = 1, rowsPerPage = 10, search = "", category_id = "") =>
    async (dispatch, getState) => {
        const config = getHeaderConfig(getState().User.Token);

        try {
            const res = await axios.get(
                `${getBaseURL()}/plots?page=${page}&rowsPerPage=${rowsPerPage}&search=${search}&category_id=${category_id}`,
                config
            );

            dispatch(ReducerActions.setPlotsData(res.data.data));
        } catch (error) {
            console.log(error);
            return error?.response?.data?.message;
        }
    };

export const GeneratePlots = (data) => async (dispatch, getState) => {
    const config = getHeaderConfig(getState().User.Token);

    const body = {
        category_id: data.category_id,
        plots: data.plots,
        plot_category_prefix: data.plot_category_prefix,
        start_plot_number_from: data.start_plot_number_from,
        total_plots: data.total_plots,
    };

    try {
        const res = await axios.post(
            `${getBaseURL()}/plots/store`,
            body,
            config
        );

        // return res.data.message;
    } catch (error) {
        console.log(error);
        return error?.response?.data?.message;
    }
};

export const getPlotDataById = (id) => async (dispatch, getState) => {
    const config = getHeaderConfig(getState().User.Token);

    try {
        const res = await axios.get(
            `${getBaseURL()}/plots/application/${id}`,
            config
        );

        dispatch(ReducerActions.setSinglePlotData(res.data.data));
    } catch (error) {
        console.log(error);
        return error?.response?.data?.message;
    }
};
