import axios from "axios";
import { getBaseURL } from "../../helpers/getBaseURL";
import { getHeaderConfig } from "../../helpers/getHeaderConfig";
import * as ReducerActions from "../reducers";

export const loginAction = (Email, Password) => async (dispatch, getState) => {
    try {
        const body = {
            email: Email,
            password: Password,
        };

        const res = await axios.post(`${getBaseURL()}/login`, body);

        if (!res.data?.success) return "Invalid Credentials";

        dispatch(ReducerActions.loginSuccess(res.data));
    } catch (error) {
        console.log(error);
        return "Invalid Credentials";
    }
};

export const VerifyToken = (email, pass) => async (dispatch, getState) => {
    const config = getHeaderConfig(getState().User.Token);

    try {
        const res = await axios.get(`${getBaseURL()}/verifyToken`, config);
    } catch (error) {
        dispatch(ReducerActions.LogoutSuccess());
    }
};
