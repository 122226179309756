import React, { lazy, Suspense, useEffect } from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { VerifyToken } from "./store/actions";
import axios from "axios";
import { LogoutSuccess } from "./store/reducers";
import { toast } from "react-toastify";

const ProtectedRoute = lazy(() => import("./helpers/ProtectedRoute"));

const Login = lazy(() => import("./pages/Login"));

const Dashboard = lazy(() => import("./pages/Dashboard"));

const Project = lazy(() => import("./pages/Project"));
const CreateProject = lazy(() => import("./pages/CreateProject"));

const Plots = lazy(() => import("./pages/Plots"));
const GeneratePlot = lazy(() => import("./pages/GeneratePlot"));
const PlotApplication = lazy(() => import("./pages/PlotApplication"));
const PlotTransaction = lazy(() => import("./pages/PlotTransaction"));

const Clients = lazy(() => import("./pages/Clients"));
const CreateClient = lazy(() => import("./pages/CreateClient"));

const Accounts = lazy(() => import("./pages/Accounts"));
const CreateAccount = lazy(() => import("./pages/CreateAccount"));

const FinancialCategory = lazy(() => import("./pages/FinancialCategory"));
const CreateFinancialCategory = lazy(() =>
    import("./pages/CreateFinancialCategory")
);

const Transaction = lazy(() => import("./pages/Transaction"));
const CreateTransaction = lazy(() => import("./pages/CreateTransaction"));

const Category = lazy(() => import("./pages/Category"));
const CreateCategory = lazy(() => import("./pages/CreateCategory"));

// const Users = lazy(() => import("./pages/Users"));
// const Bikes = lazy(() => import("./pages/Bikes"));

const FallbackScreen = lazy(() => import("./components/common/FallbackScreen"));

const App = (props) => {
    useEffect(() => {
        axios.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                console.log(error);
                if (
                    error.response.status === 401 &&
                    window.location.pathname !== "/login"
                ) {
                    dispatch(LogoutSuccess());
                    toast.dismiss();
                    toast.error("Session Expired");
                } else {
                }

                return Promise.reject(error);
            }
        );
    }, []);

    const IsAuth = useSelector((state) => state.User.IsAuth);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(VerifyToken());
    }, []);

    if (!IsAuth)
        return (
            <>
                <Suspense fallback={<FallbackScreen />}>
                    <Routes>
                        <Route path="/login" exact element={<Login />} />
                        <Route path="*" element={<Navigate to="/login" />} />
                    </Routes>
                </Suspense>
            </>
        );

    return (
        <Suspense fallback={<FallbackScreen />}>
            <Routes>
                <Route path="/" exact element={<Dashboard />} />

                <Route path="/show-projects" exact element={<Project />} />
                <Route
                    path="/create-project"
                    exact
                    element={<CreateProject />}
                />

                <Route path="/show-category" exact element={<Category />} />
                <Route
                    path="/create-category"
                    exact
                    element={<CreateCategory />}
                />

                <Route path="/show-plots" exact element={<Plots />} />
                <Route path="/generate-plot" exact element={<GeneratePlot />} />
                <Route
                    path="/plots/application/:id"
                    exact
                    element={<PlotApplication />}
                />
                <Route
                    path="/plot-transaction/:id"
                    exact
                    element={<PlotTransaction />}
                />

                <Route path="/show-users" exact element={<Clients />} />
                <Route path="/create-client" exact element={<CreateClient />} />
                <Route
                    path="/update-client/:id"
                    exact
                    element={<CreateClient />}
                />

                <Route path="/show-account" exact element={<Accounts />} />
                <Route
                    path="/create-account"
                    exact
                    element={<CreateAccount />}
                />

                <Route
                    path="/show-financial-category"
                    exact
                    element={<FinancialCategory />}
                />
                <Route
                    path="/create-financial-category"
                    exact
                    element={<CreateFinancialCategory />}
                />

                <Route
                    path="/show-financial-transaction"
                    exact
                    element={<Transaction />}
                />
                <Route
                    path="/create-transaction"
                    exact
                    element={<CreateTransaction />}
                />

                {/* <Route path="/users" exact element={<Users />} />
                <Route path="/bikes" exact element={<Bikes />} /> */}

                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Suspense>
    );
};

App.propTypes = {};

export default App;
