const PREFIX = "api";

export const getBaseURL = () => {
    try {
        // if (window.location.hostname === "localhost") {
            // return `http://qadeem_construction.test/${PREFIX}`;
        // } else {
            return `https://api.blackdiamondqta.com/${PREFIX}`;
            return `https://qadeem-construction.herokuapp.com/${PREFIX}`;
            return `https://construction.iqtech.pk/${PREFIX}`;
        // }
    } catch (error) {
        console.log("Some Error Occured");
        console.log(error);
    }
};
