import axios from "axios";
import { getBaseURL } from "../../helpers/getBaseURL";
import { getHeaderConfig } from "../../helpers/getHeaderConfig";
import * as ReducerActions from "../reducers";

export const getClientData =
    (page = 1, rowsPerPage = 10, search = "") =>
    async (dispatch, getState) => {
        const config = getHeaderConfig(getState().User.Token);

        try {
            const res = await axios.get(
                `${getBaseURL()}/clients?page=${page}&rowsPerPage=${rowsPerPage}&search=${search}`,
                config
            );

            dispatch(ReducerActions.setClientData(res.data.data));
        } catch (error) {
            console.log(error);
            return error?.response?.data?.message;
        }
    };

export const AddNewClient =
    (body, updateFlag) => async (dispatch, getState) => {
        const config = getHeaderConfig(getState().User.Token);

        try {
            let res = null;
            if (updateFlag) {
                res = await axios.put(
                    `${getBaseURL()}/clients/${body?.id}/update`,
                    body,
                    config
                );
            } else {
                res = await axios.post(
                    `${getBaseURL()}/clients/store`,
                    body,
                    config
                );
            }

            return false;
        } catch (error) {
            return error?.response?.data?.data || "Something went wrong";
        }
    };

export const getSingleClientData = (cnic) => async (dispatch, getState) => {
    const config = getHeaderConfig(getState().User.Token);

    try {
        const res = await axios.get(
            `${getBaseURL()}/clients?cnic=${cnic}`,
            config
        );

        dispatch(ReducerActions.setSingleClientData(res.data.data));
    } catch (error) {
        console.log(error);
        return error?.response?.data?.message;
    }
};
